import { Link } from '@remix-run/react';
import { Navigate, useNavigate } from '@remix-run/react';

import { useUserAnalytics } from '../analytics/user';
import { UserAccess } from '../components/Access/UserAccess';
import { useSlackInstalledCallbackModal } from '../components/Channel';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../components/ConfirmCancelModalContext';
import { GlobalLoading } from '../components/GlobalLoading';
import { LunaParkLogo } from '../components/icons/LogoIcon';
import { OrgRequired } from '../components/Organization';
import {
  ProgramsActivation,
  useProgramActivationData,
} from '../components/Program/ProgramsActivation';
import { ProvidersList } from '../components/ProvidersList';
import { UserContextProvider } from '../components/UserContext';
import { useQueryParam } from '../hooks/useQueryParam';
import { useTitle } from '../hooks/useTitle';
import { type Organization } from '../types';

export function Container(props: { organization: Organization }) {
  useSlackInstalledCallbackModal();
  const navigate = useNavigate();

  const programId = useQueryParam('program-id');
  const { data, isLoading, error } = useProgramActivationData(
    props.organization.id,
    {
      initSelectedProgramIds: programId ? [programId] : undefined,
    }
  );

  if (isLoading) return <GlobalLoading />;
  if (error || !data) throw error;
  const { installedProgramLinks, uninstalledPrograms, initSelectedPrograms } =
    data;

  // If the program is already installed, redirect to the program page
  if (programId) {
    const links = installedProgramLinks.filter(
      (l) => l.programId === programId
    );
    if (links.length === 1) {
      return <Navigate to={`/programs/${links[0].id}`} replace={true} />;
    }
    if (links.length > 1) {
      return <Navigate to={`/channels`} replace={true} />;
    }
  }

  // If there are no programs to install, redirect to the channels page
  if (uninstalledPrograms.length === 0) {
    return <Navigate to={'/channels'} replace={true} />;
  }
  return (
    <div
      className={`relative w-full h-full p-2 flex flex-col overflow-auto scrollbar`}
    >
      <header className='relative flex-none w-full h-20 flex justify-center items-center px-60'>
        <div className='absolute left-5'>
          <Link to='/home'>
            <LunaParkLogo className='w-45 h-12.5' />
          </Link>
        </div>
      </header>

      <main className='flex-1 mb-20 flex flex-col justify-center items-center'>
        <ProgramsActivation
          orgId={props.organization.id}
          programs={uninstalledPrograms}
          initSelectedPrograms={initSelectedPrograms}
          onSkip={() => navigate('/home')}
        />
      </main>
    </div>
  );
}

export function Component() {
  useTitle('Programs Activation');

  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <ConfirmCancelModalProvider />,
    <UserAccess />,
  ];
  return (
    <ProvidersList providers={providers}>
      <OrgRequired>
        {(organization) => <Container organization={organization} />}
      </OrgRequired>

      <ConfirmCancelModalRoot />
    </ProvidersList>
  );
}
